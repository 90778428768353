<template>
  <div class="l-wrapper">
    <AppHeader />
    <main class="l-main -login">
      <div class="l-lead">
        <h1 class="c-lead">パスワード再設定</h1>
      </div>
      <div class="l-contents-white l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <p class="l-block">
          新しいパスワードを設定してください。<br />
          パスワードは英数字8文字以上で入力してください。
        </p>
        <form>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>新パスワード</label>
            </div>
            <div class="c-inputWrap__items">
              <InputPassword
                :value.sync="newPassword"
                tooltip="英字と数字を組み合わせて8文字以上で入力してください。"
                :validation="validatePsw"
                :isValid.sync="isValidOf.newPassword.isValid"
                :errMsg.sync="isValidOf.newPassword.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>新パスワード（再）</label>
            </div>
            <div class="c-inputWrap__items">
              <InputPassword
                :value.sync="confirmPassword"
                tooltip="英字と数字を組み合わせて8文字以上で入力してください。"
                :validation="validatePsw"
                :isValid.sync="isValidOf.confirmPassword.isValid"
                :errMsg.sync="isValidOf.confirmPassword.errMsg"
              />
            </div>
          </div>
        </form>
      </div>
      <!-- /.l-contents-white -->
      <div class="l-btns">
        <div class="c-btn primary mid" @click="submit">設定</div>
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputPassword from "@/components/parts/input/InputPassword";
import validation from "@/mixin/validation";
import { API_URL } from "../../const/index";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "password-reminder",
  components: {
    InputPassword,
  },
  mixins: [validation],
  data() {
    return {
      memberId: null,
      token: null,
      newPassword: "",
      confirmPassword: "",
      isValidOf: {
        newPassword: { isValid: true, errMsg: "" },
        confirmPassword: { isValid: true, errMsg: "" },
      },
      errMsgs: [],
    };
  },
  methods: {
    async submit() {
      if (!this.runValidate()) return;

      const reqParams = {
        password: this.newPassword,
        memberId: this.memberId,
        token: this.token,
      };
      await axios
        .post(API_URL.REMINDER.SETTING, reqParams)
        .then(() => {
          this.$router.push("/login");
        })
        .catch((err) => {
          this.errMsgs = [];
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          console.log(err);
        });
    },

    runValidate() {
      let isValidForm = true;
      if (!this.isValidOf.newPassword.isValid) {
        this.isValidOf.newPassword.errMsg = "英字と数字を組み合わせて8文字以上で入力してください。";
        isValidForm = false;
      }
      if (!this.isValidOf.confirmPassword.isValid) {
        this.isValidOf.confirmPassword.errMsg = "英字と数字を組み合わせて8文字以上で入力してください。";
        isValidForm = false;
      }

      if (this.newPassword !== this.confirmPassword) {
        this.isValidOf.confirmPassword.errMsg = "パスワードが一致していません";
        isValidForm = false;
      }

      return isValidForm;
    },
  },
  mounted() {
    // public系の画面はログアウトさせる
    this.$store.commit("auth/set", false);
    this.$store.commit("userInfo/set", {});

    this.memberId = this.$route.params.id;
    this.token = this.$route.query.token;
  },
};
</script>
